import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { LoanItem } from 'src/components/LoanItem/LoanItem';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    LoanApplicationListDTO,
    LoanApplicationStatus,
} from 'src/generated-api-client';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = ApplicationsStore;

export const LoanApplications = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        ApplicationsStore.loadList();

        return () => {
            ApplicationsStore.listLoader.reset();
        };
    }, []);

    return (
        <BasePage
            isLoading={ApplicationsStore.listLoader.isLoading}
            title={t('Page.LoanApplications.Title')}
        >
            {store.applicationsSortedByDateInDesc.map(
                (application: LoanApplicationListDTO) => (
                    <LoanItem
                        id={application?.id}
                        date={application.submitDate}
                        dateText={t('Page.LoanApplications.DateText')}
                        status={t(
                            `Loan.Application.Status.${application?.status}`,
                        )}
                        isFinished={finishedApplicationStatuses.includes(
                            application.status as LoanApplicationStatus,
                        )}
                        linkAddress={UrlHelper.getTargetUrl(
                            RoutePaths.loanApplication,
                            {
                                id: application?.id,
                            },
                        )}
                        statusColor={
                            applicationStatusMapColors[
                                application.status as keyof typeof applicationStatusMapColors
                            ]
                        }
                        amount={
                            applicationStatusesForApprovedProperties.includes(
                                application.status as LoanApplicationStatus,
                            )
                                ? application.approvedAmount
                                : application.requestedAmount
                        }
                        period={
                            applicationStatusesForApprovedProperties.includes(
                                application.status as LoanApplicationStatus,
                            )
                                ? application.approvedTerm
                                : application.requestedTerm
                        }
                        type={application.type}
                        discloserType={application.disclosure}
                    />
                ),
            )}
        </BasePage>
    );
});

export const applicationStatusesForApprovedProperties = [
    LoanApplicationStatus.OFFERISSUED,
    LoanApplicationStatus.SIGNINGPROCESS,
    LoanApplicationStatus.SIGNED,
    LoanApplicationStatus.EXPIRED,
    LoanApplicationStatus.DECLINEDBYCUSTOMER,
];

export const applicationStatusMapColors = {
    [LoanApplicationStatus.INITIALWAITINGDOCUMENTS]: '#FFFF80',
    [LoanApplicationStatus.CREDITBUREAUCHECKING]: '#FFFF80',
    [LoanApplicationStatus.INITIALREVIEW]: '#FFFF80',
    [LoanApplicationStatus.INFOREQUESTED]: '#FFFF80',
    [LoanApplicationStatus.CREDITCOMMITTEEREVIEW]: '#FFFF80',
    [LoanApplicationStatus.OFFERISSUED]: '#D6F7A0',
    [LoanApplicationStatus.SIGNINGPROCESS]: '#D6F7A0',
    [LoanApplicationStatus.FINALOFFERISSUED]: '#D6F7A0',
    [LoanApplicationStatus.SIGNED]: '#D6F7A0',
    [LoanApplicationStatus.EXPIRED]: '#FFFF80',
    [LoanApplicationStatus.DECLINEDBYREVIEWER]: '#EB7382',
    [LoanApplicationStatus.DECLINEDBYCREDITBUREAU]: '#EB7382',
    [LoanApplicationStatus.DECLINEDBYCREDITCOMMITTEE]: '#EB7382',
    [LoanApplicationStatus.DECLINEDBYCUSTOMER]: '#EB7382',
    [LoanApplicationStatus.WAITINGACCOUNTVERIFICATION]: '#FFFF80',
};

const finishedApplicationStatuses = [
    LoanApplicationStatus.DECLINEDBYREVIEWER,
    LoanApplicationStatus.DECLINEDBYCREDITBUREAU,
    LoanApplicationStatus.DECLINEDBYCREDITCOMMITTEE,
    LoanApplicationStatus.SIGNED,
    LoanApplicationStatus.EXPIRED,
    LoanApplicationStatus.DECLINEDBYCUSTOMER,
];
