/* eslint-disable max-lines */
// @ts-nocheck
import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DnDUploader } from 'src/components/DnDUploader/DnDUploader';
import { FilesListComponent } from 'src/components/FilesListComponent/FilesListComponent';
import {
    FileMeasures,
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import {
    DocumentDTO,
    LoanApplicationDisclosure,
    LoanApplicationViewDTO,
} from 'src/generated-api-client';
import { ApplicationDocumentsStore } from 'src/stores/ApplicationDocumentsStore/ApplicationDocumentsStore';
import styled from 'styled-components';

type ProvideAdditionalDocumentProps = {
    currentApplication: LoanApplicationViewDTO;
};

const { Title } = Typography;

const TAX_LABEL = 'Tax invoice (to be discounted)';
const CONTRACT = 'Customer contract';
const BANK_LABEL = 'The most recent bank statement (at least 3-6 month)';
const DELIVERY_NOTE = 'Delivery note / Goods receiver voucher';

const docTypes = {
    INVOICE_FINANCE: [
        {
            label: TAX_LABEL,
            value: 'LOAN_APPLICATION_TAX_INVOICE',
        },
        {
            label: CONTRACT,
            value: 'LOAN_APPLICATION_INVOICE_CONTRACT',
        },
        {
            label: DELIVERY_NOTE,
            value: 'LOAN_APPLICATION_DELIVERY_NOTE',
        },
    ],
    OUT_AND_OUT_INVOICE_FINANCE: [
        {
            label: TAX_LABEL,
            value: 'LOAN_APPLICATION_TAX_INVOICE',
        },
        {
            label: CONTRACT,
            value: 'LOAN_APPLICATION_INVOICE_CONTRACT',
        },
        {
            label: DELIVERY_NOTE,
            value: 'LOAN_APPLICATION_DELIVERY_NOTE',
        },
    ],
    FINANCE_OF_DEBTOR: [
        {
            label: 'Contract with debtors, which shall be financed',
            value: 'LOAN_APPLICATION_DEBTORS_CONTRACT',
        },
        {
            label: 'Latest Audited FS or Signed Management Account\n',
            value: 'LOAN_APPLICATION_FINANCIAL_STATEMENT',
        },
        {
            label: 'Accounts payable / receivable aging analysis',
            value: 'LOAN_APPLICATION_ACCOUNTS_AGING',
        },
        {
            label: 'Order profit and loss budget',
            value: 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET',
        },
        {
            label: DELIVERY_NOTE,
            value: 'LOAN_APPLICATION_DELIVERY_NOTE',
        },
    ],
    PURCHASE_ORDER_FINANCE: [
        {
            label: 'Purchase order',
            value: 'LOAN_APPLICATION_PURCHASE_ORDER',
        },
        {
            label: 'Contract for this purchase order',
            value: 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT',
        },
        {
            label: 'Order profit and loss budget',
            value: 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET',
        },
        {
            label: 'Accounts payable / receivable aging analysis',
            value: 'LOAN_APPLICATION_ACCOUNTS_AGING',
        },
        {
            label: 'Quotations (1-3 items)',
            value: 'LOAN_APPLICATION_QUOTATION',
            maxCount: 3,
        },
        {
            label: DELIVERY_NOTE,
            value: 'LOAN_APPLICATION_DELIVERY_NOTE',
        },
    ],
};

const documentsTypesStoreCorrelation = {
    LOAN_APPLICATION_PURCHASE_ORDER: 'applicationPurcheseOrder',
    LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT:
        'applicationPurcheseOrderContract',
    LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET: 'applicationProfitAndLossBudget',
    LOAN_APPLICATION_ACCOUNTS_AGING: 'customerSignedAgreement',
    LOAN_APPLICATION_FINANCIAL_STATEMENT: 'applicationFinancialStatement',
    LOAN_APPLICATION_TAX_INVOICE: 'applicationTaxInvoice',
    LOAN_APPLICATION_INVOICE_CONTRACT: 'applicationInvoiceContract',
    LOAN_APPLICATION_DEBTORS_CONTRACT: 'applicationDebtorsContract',
    LOAN_APPLICATION_QUOTATION: 'applicationQuotations',
    LOAN_APPLICATION_DELIVERY_NOTE: 'applicationDeliveryNote',
};

export const ProvideAdditionalDocument = observer(
    ({ currentApplication }: ProvideAdditionalDocumentProps) => {
        const { t } = useTranslation();

        const store = ApplicationDocumentsStore.getInstance(
            currentApplication?.id,
        );

        useEffect(() => {
            store.loadList();
        }, []);

        return (
            <StyledWrapper>
                <Row gutter={24} justify="start">
                    <Col>
                        <Title level={4}>
                            {t(
                                'Dialog.ProvideAdditionalDocument.ApplicationDocuments',
                                'Application documents:',
                            )}
                        </Title>
                    </Col>
                </Row>
                <Row gutter={24} justify="space-between">
                    {docTypes[
                        currentApplication.type as keyof typeof docTypes
                    ].map((item) => (
                        <Col span={11}>
                            <DnDUploader
                                maxCount={item?.maxCount || 20}
                                name={['documents', item.value]}
                                label={item.label}
                                rules={[
                                    ValidationRules.maxFileSize(
                                        25,
                                        FileSizeUnit.Mb,
                                        FileMeasures.mb,
                                    ),
                                    ValidationRules.validateFilesType,
                                ]}
                            />

                            <FilesListComponent
                                filesList={
                                    store?.[
                                        documentsTypesStoreCorrelation?.[
                                            item.value
                                        ]
                                    ] as DocumentDTO[]
                                }
                                store={store}
                            />
                        </Col>
                    ))}
                    {currentApplication.disclosure ===
                        LoanApplicationDisclosure.DISCLOSED && (
                        <Col span={11}>
                            <DnDUploader
                                maxCount={20}
                                name={[
                                    'documents',
                                    'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER',
                                ]}
                                label={t(
                                    'Dialog.ProvideAdditionalDocument.Counterparty',
                                    'Applicant Bank Account Confirmation Letter',
                                )}
                                rules={[
                                    ValidationRules.maxFileSize(
                                        25,
                                        FileSizeUnit.Mb,
                                        FileMeasures.mb,
                                    ),
                                    ValidationRules.validateFilesType,
                                ]}
                            />

                            <FilesListComponent
                                filesList={
                                    store.applicationProdLetter as DocumentDTO[]
                                }
                                store={store}
                            />
                        </Col>
                    )}
                </Row>
                <Row gutter={24} justify="start">
                    <Col>
                        <Title level={4}>
                            {t(
                                'Dialog.ProvideAdditionalDocument.SolvencyDocuments',
                                'Solvency documents:',
                            )}
                        </Title>
                    </Col>
                </Row>
                <Row gutter={24} justify="space-between">
                    <Col span={11}>
                        <DnDUploader
                            maxCount={20}
                            name={[
                                'documents',
                                'LOAN_APPLICATION_BANK_STATEMENT',
                            ]}
                            label={BANK_LABEL}
                            rules={[
                                ValidationRules.maxFileSize(
                                    25,
                                    FileSizeUnit.Mb,
                                    FileMeasures.mb,
                                ),
                                ValidationRules.validateFilesType,
                            ]}
                        />

                        <FilesListComponent
                            filesList={
                                store.applicationBankStatement as DocumentDTO[]
                            }
                            store={store}
                        />
                    </Col>
                </Row>
            </StyledWrapper>
        );
    },
);

const StyledWrapper = styled.div`
    margin: 22px 0;
`;
