/* eslint-disable max-lines */
import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { DnDUploader } from 'src/components/DnDUploader/DnDUploader';
import {
    FileMeasures,
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { Router } from 'src/core/router/Router';
import { LoanApplicationDisclosure } from 'src/generated-api-client';
import { ApplyForALoanStore } from 'src/stores/ApplyForALoanStore/ApplyForALoanStore';
import styled from 'styled-components';

const { Title } = Typography;

const store = ApplyForALoanStore;

const docTypes = {
    INVOICE_FINANCE: [
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.Tax"
                    defaults="Tax invoice (to be discounted)"
                />
            ),
            value: 'LOAN_APPLICATION_TAX_INVOICE',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.InvoiceContract"
                    defaults="Customer contract"
                />
            ),
            value: 'LOAN_APPLICATION_INVOICE_CONTRACT',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ThirdStepSignupPage.LOAN_APPLICATION_DELIVERY_NOTE"
                    defaults="CDelivery note / Goods receiver voucher"
                />
            ),
            value: 'LOAN_APPLICATION_DELIVERY_NOTE',
        },
    ],
    OUT_AND_OUT_INVOICE_FINANCE: [
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.Tax"
                    defaults="Tax invoice (to be discounted)"
                />
            ),
            value: 'LOAN_APPLICATION_TAX_INVOICE',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.InvoiceContract"
                    defaults="Customer contract"
                />
            ),
            value: 'LOAN_APPLICATION_INVOICE_CONTRACT',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ThirdStepSignupPage.LOAN_APPLICATION_DELIVERY_NOTE"
                    defaults="CDelivery note / Goods receiver voucher"
                />
            ),
            value: 'LOAN_APPLICATION_DELIVERY_NOTE',
        },
    ],
    FINANCE_OF_DEBTOR: [
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.ContractDebtors"
                    defaults="Contract with debtors, which shall be financed"
                />
            ),
            value: 'LOAN_APPLICATION_DEBTORS_CONTRACT',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.AuditedFinancial"
                    defaults="Latest Audited FS or Signed Management Account"
                />
            ),
            value: 'LOAN_APPLICATION_FINANCIAL_STATEMENT',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.AccountPayable"
                    defaults="Accounts payable / receivable aging analysis"
                />
            ),
            value: 'LOAN_APPLICATION_ACCOUNTS_AGING',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.OrderProfit"
                    defaults="Order profit and loss budget"
                />
            ),
            value: 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ThirdStepSignupPage.LOAN_APPLICATION_DELIVERY_NOTE"
                    defaults="CDelivery note / Goods receiver voucher"
                />
            ),
            value: 'LOAN_APPLICATION_DELIVERY_NOTE',
        },
    ],
    PURCHASE_ORDER_FINANCE: [
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.PurchaseOrder"
                    defaults="Purchase order"
                />
            ),
            value: 'LOAN_APPLICATION_PURCHASE_ORDER',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.OrderContract"
                    defaults="Contract for this purchase order"
                />
            ),
            value: 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT',
            required: false,
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.OrderProfit"
                    defaults="Order profit and loss budget"
                />
            ),
            value: 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.AccountPayable"
                    defaults="Accounts payable / receivable aging analysis"
                />
            ),
            value: 'LOAN_APPLICATION_ACCOUNTS_AGING',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.AuditedFinancial"
                    defaults="Audited financial statement or management accounts"
                />
            ),
            value: 'LOAN_APPLICATION_FINANCIAL_STATEMENT',
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ApplyForALoan.ApplicationDocuments.Quotations"
                    defaults="Quotations (1-3 items)"
                />
            ),
            value: 'LOAN_APPLICATION_QUOTATION',
            maxCount: 3,
        },
        {
            label: (
                <Trans
                    i18nKey="Page.ThirdStepSignupPage.LOAN_APPLICATION_DELIVERY_NOTE"
                    defaults="CDelivery note / Goods receiver voucher"
                />
            ),
            value: 'LOAN_APPLICATION_DELIVERY_NOTE',
        },
    ],
};

export const ThirdStepCommonFiles = observer(() => {
    const { t } = useTranslation();

    const applicationType =
        new URLSearchParams(Router.getQuerySearchParams()).get('dealType') ||
        store.initialApplicationData?.type;

    const disclosureType =
        new URLSearchParams(Router.getQuerySearchParams()).get('disclosure') ||
        store.initialApplicationData?.disclosure;

    return (
        <>
            <Row gutter={24} justify="start">
                <Col>
                    <StyledPageTitle level={3}>
                        {t(
                            'Page.ApplyForALoan.ApplicationDocuments.Title',
                            'Application documents:',
                        )}
                    </StyledPageTitle>
                </Col>
            </Row>
            <Row gutter={24} justify="space-between">
                {/* @ts-ignore */}
                {docTypes[applicationType].map((item) => (
                    <Col span={11}>
                        <DnDUploader
                            maxCount={item?.maxCount || 20}
                            name={item.value}
                            label={item.label}
                            rules={
                                item.required === false
                                    ? [
                                          ValidationRules.maxFileSize(
                                              25,
                                              FileSizeUnit.Mb,
                                              FileMeasures.mb,
                                          ),
                                          ValidationRules.validateFilesType,
                                      ]
                                    : [
                                          ValidationRules.required,
                                          ValidationRules.maxFileSize(
                                              25,
                                              FileSizeUnit.Mb,
                                              FileMeasures.mb,
                                          ),
                                          ValidationRules.validateFilesType,
                                      ]
                            }
                        />
                    </Col>
                ))}
                {disclosureType === LoanApplicationDisclosure.DISCLOSED && (
                    <Col span={11}>
                        <DnDUploader
                            maxCount={20}
                            name="LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER"
                            label={t(
                                'Page.ApplyForALoan.ApplicationDocuments.BankLetter',
                                'Applicant Bank Account Confirmation Letter',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxFileSize(
                                    25,
                                    FileSizeUnit.Mb,
                                    FileMeasures.mb,
                                ),
                                ValidationRules.validateFilesType,
                            ]}
                        />
                    </Col>
                )}
            </Row>
        </>
    );
});

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: left;
    margin-top: 25px;
`;
