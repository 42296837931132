import { Button, Layout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LanguageSwitcher } from 'src/components/LanguageSwitcher/LanguageSwitcher';
import { Logo } from 'src/components/Logo';
import { UserMenu } from 'src/components/UserMenu';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { CustomerVerificationStatus } from 'src/generated-api-client';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { CssVariables } from 'src/styles/CssVariables';
import styled from 'styled-components';

const store = ProfileStore;
const loanStore = ApplicationsStore;

export const Header = observer(() => {
    const { t } = useTranslation();

    const isVerificationStatus = [
        CustomerVerificationStatus.VERIFIED,
        CustomerVerificationStatus.INREVIEW,
    ].includes(store.user?.verificationStatus as CustomerVerificationStatus);

    return (
        <StyledHeader>
            <StyledAppHeader>
                <Logo />
                <StyledMenu mode="horizontal">
                    <StyledMenuInner>
                        {isVerificationStatus && (
                            <Menu.Item key={1}>
                                <NavLink
                                    to={RoutePaths.loanApplications}
                                    activeClassName="active"
                                >
                                    {t('App.Menu.Applications')}
                                </NavLink>
                            </Menu.Item>
                        )}
                        {isVerificationStatus && (
                            <Menu.Item key={2}>
                                <NavLink
                                    to={RoutePaths.loans}
                                    activeClassName="active"
                                >
                                    {t('App.Menu.Loans', 'Loans')}
                                </NavLink>
                            </Menu.Item>
                        )}

                        <Menu.Item key={3}>
                            <NavLink
                                to={RoutePaths.dashboard}
                                activeClassName="active"
                            >
                                {t('App.Menu.Dashboard', 'Dashboard')}
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key={4}>
                            <NavLink
                                to={RoutePaths.faq}
                                activeClassName="active"
                            >
                                {t('App.Menu.FAQ', 'FAQ')}
                            </NavLink>
                        </Menu.Item>
                    </StyledMenuInner>
                    {isVerificationStatus &&
                        !loanStore.hasWaitingDocumentsApplicationsList && (
                            <ApplyForALoan
                                onClick={() => {
                                    Router.navigate(
                                        `${RoutePaths.applyForALoan}/1`,
                                    );
                                }}
                            >
                                {t('App.Menu.ApplyForALoan')}
                            </ApplyForALoan>
                        )}
                </StyledMenu>
                <LanguageSwitcher />
                <UserMenu />
            </StyledAppHeader>
        </StyledHeader>
    );
});

const StyledAppHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledHeader = styled(Layout.Header)`
    padding: 0 29px;
    box-shadow: 0 2px 8px #d9d9d9;
    height: ${CssVariables.headerHeight};
    background-color: #ffffff;
    line-height: ${CssVariables.headerLineHeight};
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    overflow: hidden;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    flex: 1;
    align-items: center;
    color: #000000;
    margin: 15px 20px;

    .ant-menu-sub {
        a.active {
            color: #000000 !importatnt;
        }
    }

    .ant-menu-submenu-title {
        margin-top: 5px;
    }

    .ant-menu-submenu-title,
    .ant-menu-title-content {
        span,
        a {
            color: #000000 !important;
            font-weight: 600;
            font-size: 1.2rem !important;
            margin-left: 15px;

            &:hover {
                color: #a4a6a8;
                text-decoration: none;
            }
        }
    }

    &.ant-menu-horizontal {
        border: none;
    }

    &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
        padding: 0;
    }

    .ant-menu-sub,
    .ant-menu-submenu,
    .ant-menu-item {
        &:after {
            display: none;
        }

        + .ant-menu-item {
            margin-left: 1px;
        }

        span,
        a {
            font-size: 1.2rem;
            line-height: 165%;
            display: inline-block;
            padding: 3px 17px;
            border-radius: 6px;

            letter-spacing: -0.02em;

            color: #a4a6a8;
            background-color: transparent;

            transition: background-color 0.2s, easy-in-out;

            &:hover {
                opacity: 0.7;
            }
        }

        a.active {
            border: 1px solid #000000;
            border-radius: 10px;
            color: #000000;
            background: transparent;
        }
    }
`;

const StyledMenuInner = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

const ApplyForALoan = styled(Button)`
    width: 160px;
    height: 30px;
    margin-left: 9px;
    border-radius: 10px;
    border: none;
    background: #000000;
    color: #ffffff;
    &:focus,
    &:active,
    &:hover {
        background: #00000070;
        color: #ffffff;
        border: none;
    }
`;
