// @ts-nocheck
/* eslint-disable import/no-cycle */
/* eslint-disable max-lines */
import axios from 'axios';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    FeeRepaymentMethod,
    LoanApplicationDisclosure,
    LoanApplicationFinancedItemDTO,
    LoanApplicationForm,
    LoanApplicationListDTO,
    LoanApplicationStatus,
    LoanApplicationType,
    LoanApplicationViewDTO,
} from 'src/generated-api-client';
import { applicationsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

import { UploadFilesStatusStore } from '../UploadFilesStatusStore/UploadFilesStatusStore';

export enum LoanStepId {
    FIRST = '1',
    SECOND = '2',
    THIRD = '3',
}

export class ApplyForALoanStoreStoreClass extends BasicStore<
    LoanApplicationListDTO,
    any,
    any,
    any,
    LoanApplicationViewDTO | undefined
> {
    @observable private formApplication?: LoanApplicationViewDTO = {
        feeRepaymentMethod: FeeRepaymentMethod.ADDTOLOAN,
        disclosure: LoanApplicationDisclosure.DISCLOSED,
        financedItems: [
            {
                amount: undefined,
                creditNote: 0,
                itemNumber: undefined,
                maturityDate: undefined,
                originationDate: undefined,
            },
        ],
    };

    api: BasicStoreApi<
        LoanApplicationListDTO,
        any,
        any,
        LoanApplicationViewDTO | undefined
    > = {
        loadItem: async (id: number) => {
            const response = await RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.entityView2(id),
            );
            this.formApplication = {
                id: response.data?.id,
                financedItems: response.data?.financedItems?.reduce(
                    (init, current) => {
                        return [
                            ...init,
                            {
                                ...current,
                                maturityDate: moment(current.maturityDate),
                                originationDate: moment(
                                    current.originationDate,
                                ),
                            },
                        ];
                    },
                    [] as LoanApplicationFinancedItemDTO[],
                ) as LoanApplicationFinancedItemDTO[] | undefined,
                bankAccountId: response.data?.bankAccount?.id,
                disclosure: response.data?.disclosure,
                feeRepaymentMethod: response.data?.feeRepaymentMethod,
                purchaseOrderDescription:
                    response.data?.purchaseOrderDescription,
                requestedTerm: response.data?.requestedTerm,
                thirdPartyAccountName:
                    response.data?.thirdParty?.bankAccount?.accountName,
                thirdPartyAccountNumber:
                    response.data?.thirdParty?.bankAccount?.accountNumber,
                thirdPartyAccountType:
                    response.data?.thirdParty?.bankAccount?.accountType,
                thirdPartyAddress: response.data?.thirdParty?.address,
                thirdPartyBankName:
                    response.data?.thirdParty?.bankAccount?.bankName,
                thirdPartyBranchCode:
                    response.data?.thirdParty?.bankAccount?.branchCode,
                thirdPartyCipc: response.data?.thirdParty?.cipc,
                thirdPartyEmail: response.data?.thirdParty?.email,
                thirdPartyLegalName: response.data?.thirdParty?.legalName,
                type: response.data?.type,
            };

            return response.data;
        },

        createItem: async (data) => {
            const response = await RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.saveStep21(data),
            );

            if (response.data) {
                Router.setQuerySearchParams(
                    `${RoutePaths.applyForALoan}/${LoanStepId.THIRD}`,
                    `applicationId=${response?.data?.id}&dealType=${response?.data?.type}&disclosure=${response?.data?.disclosure}`,
                );
            }

            return response.data;
        },

        updateItem: async (data: LoanApplicationForm) => {
            const response = await RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.updateApplication(data),
            );

            if (response.data) {
                Router.setQuerySearchParams(
                    `${RoutePaths.applyForALoan}/${LoanStepId.THIRD}`,
                    `applicationId=${response?.data?.id}&dealType=${response?.data?.type}`,
                );
            }

            return response.data;
        },
    };

    uploadApplicationDocumentsAction = new AsyncOperationWithStatus(
        (applicationId: string, type: string, files: any) => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.UploadDocuments(applicationId, type, files, {
                    onUploadProgress: (progressEvent: any) => {
                        UploadFilesStatusStore.onUploadProgressAction(
                            progressEvent,
                            type,
                        );
                    },
                }),
            );
        },
    );

    createApplicationItemAction = new AsyncOperationWithStatus(
        (application: LoanApplicationForm) => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.saveStep21(application),
            );
        },
    );

    confirmApplicationItemAction = new AsyncOperationWithStatus(
        (applicationId: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.startApplicationProcessing(
                    Number(applicationId),
                ),
            );
        },
    );

    loadApplicationItemAction = new AsyncOperationWithStatus(
        (applicationId: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.entityView1(applicationId),
            );
        },
    );

    cancelApplicationItemAction = new AsyncOperationWithStatus(
        (applicationId: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                applicationsApi.cancelApplication(applicationId),
            );
        },
    );

    @action async confirmApplicationItem(applicationId: string) {
        await this.confirmApplicationItemAction.call(applicationId);
        if (!this.createApplicationItemAction.hasError) {
            Router.navigate(RoutePaths.dashboard);
        }
    }

    @action async uploadApplicationDocuments(
        documents: [type: string, files: any][],
        applicationId: string,
    ) {
        await axios.all(
            documents.map(([type, files]) => {
                return this.uploadApplicationDocumentsAction.call(
                    applicationId as string,
                    type as string,
                    files,
                );
            }),
        );
        UploadFilesStatusStore.resetProgress();
    }

    @action async sendToInitialReview(applicationId: string) {
        await this.confirmApplicationItemAction.call(applicationId);
    }

    @action setFormLoanApplicationData(application: any) {
        this.formApplication = application;
    }

    @action setDealType(dealType: LoanApplicationType) {
        this.formApplication = { ...this.formApplication, type: dealType };
    }

    @action resetFormApplication() {
        this.formApplication = {
            feeRepaymentMethod: FeeRepaymentMethod.ADDTOLOAN,
            disclosure: LoanApplicationDisclosure.DISCLOSED,
            financedItems: [
                {
                    amount: undefined,
                    creditNote: 0,
                    itemNumber: undefined,
                    maturityDate: undefined,
                    originationDate: undefined,
                },
            ],
        };
    }

    @action async cancelApplicationItem(applicationId: number) {
        await this.cancelApplicationItemAction.call(applicationId);
        if (!this.cancelApplicationItemAction.hasError) {
            Router.navigate(RoutePaths.dashboard);
        }
    }

    @computed get initialApplicationData() {
        return this.formApplication;
    }

    @computed get initialDisclosure() {
        return this.formApplication?.disclosure;
    }

    @computed get formDeatType() {
        return this.formApplication?.type;
    }

    @computed get waitDocumentsApplicationsList() {
        return this.list.filter(
            (item) =>
                item.status === LoanApplicationStatus.INITIALWAITINGDOCUMENTS,
        );
    }
}

export const ApplyForALoanStore = new ApplyForALoanStoreStoreClass();
