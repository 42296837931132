import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
    LoanApplicationDisclosure,
    LoanApplicationStatus,
    LoanApplicationTerm,
    LoanApplicationType,
} from 'src/generated-api-client';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

type LoanItemProps = {
    // eslint-disable-next-line react/require-default-props
    id?: number;
    // eslint-disable-next-line react/require-default-props
    date?: string;
    // eslint-disable-next-line react/require-default-props
    dateText?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    amount?: number;
    // eslint-disable-next-line react/require-default-props
    period?: LoanApplicationTerm;
    // eslint-disable-next-line react/require-default-props
    status?: LoanApplicationStatus | any;
    // eslint-disable-next-line react/require-default-props
    type?: LoanApplicationType;
    // eslint-disable-next-line react/require-default-props
    statusColor?: string;
    // eslint-disable-next-line react/require-default-props
    discloserType?: LoanApplicationDisclosure;
    // eslint-disable-next-line react/require-default-props
    linkAddress?: string;
    isFinished: boolean;
};

export const LoanItem = ({
    id,
    date,
    dateText,
    amount,
    period,
    status,
    type,
    statusColor,
    discloserType,
    linkAddress,
    isFinished,
}: LoanItemProps) => {
    const { t } = useTranslation();
    const loanAmount = NumberHelper.formatThousandSeparator(amount as number);

    return (
        <StyledContainer to={linkAddress} finished={isFinished} key={id}>
            <StyledFlexColumnContainer>
                <StyledItem>
                    <StyledApplicationTitle>
                        {date && (
                            <>
                                {dateText}: {moment(date).format('DD.MM.YYYY')}
                            </>
                        )}
                    </StyledApplicationTitle>
                </StyledItem>
                <StyledItem>
                    <StyledStatus color={statusColor}>{status}</StyledStatus>
                </StyledItem>
            </StyledFlexColumnContainer>
            <div>
                <StyledAmounyContainer>
                    <span>{loanAmount}</span>
                    {t('Loan.Application.Term.RandsFor')}
                </StyledAmounyContainer>
                <StyledAmounyContainer>
                    <span>{t(`Loan.Application.Term.${period}`)}</span>
                    {t('Loan.Application.Term.Period')}
                </StyledAmounyContainer>
            </div>
            <div>
                <StyledApplicationTypes>
                    {t(`Loan.Application.Type.${type}`)}
                </StyledApplicationTypes>
                <StyledApplicationTypes>
                    {t(`Loan.Application.Disclosure.${discloserType}`)}
                </StyledApplicationTypes>
            </div>
        </StyledContainer>
    );
};

const StyledContainer = styled(NavLink)<{ finished: boolean }>`
    color: #000000;
    transition: all ease-in-out 0.2s;
    opacity: ${(props) => (props.finished ? 0.4 : 1)};
    border-radius: 6px;
    background: #f5f5f580;
    padding: 24px 48px;
    margin-bottom: 25px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    max-width: 1400px;
    cursor: pointer;
    box-shadow: 0px 0px 16px -2px rgb(0 0 0 / 26%);
    &:hover {
        color: #000000;
        background: #e9e9e990;
    }
`;

const StyledStatus = styled.div<{ color?: string }>`
    min-width: 280px;
    display: flex;
    border: 1px solid #79797970;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    background: ${(props) => props.color};
    border-radius: 5px;
    min-height: 35px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    letter-spacing: 1px;
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    @media only screen and (max-width: 992px) {
        min-width: initial;
    }
`;

const StyledApplicationTitle = styled.div`
    font-size: 20px;
`;

const StyledFlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StyledItem = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
`;

const StyledAmounyContainer = styled.div`
    text-align: center;
    font-size: 20px;
    span {
        font-weight: 600;
    }
    span::after {
        content: ' ';
    }
`;

const StyledApplicationTypes = styled.div`
    text-align: end;
    font-size: 20px;
    font-weight: 600;
    margin-right: 70px;
`;
