import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { FilesListComponent } from 'src/components/FilesListComponent/FilesListComponent';
import { Spinner } from 'src/components/Spinner/Spinner';
import { DocumentDTO, LoanApplicationType } from 'src/generated-api-client';
import {
    ApplicationDocumentsStore,
    ApplicationDocumentsStoreClass,
} from 'src/stores/ApplicationDocumentsStore/ApplicationDocumentsStore';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';
import styled from 'styled-components';
/* eslint-disable max-lines */

export const DocumentsTab = observer(() => {
    const store = ApplicationDocumentsStore.getInstance(
        ApplicationStore.currentItem?.id,
    );
    const { t } = useTranslation();

    useEffect(() => {
        store.loadList();
    }, []);

    const dealList = getDealList(store);
    const financialList = getFinancialList(store);

    return store.listLoader.isLoading ? (
        <StyledSpinnerContainer>
            <Spinner />
        </StyledSpinnerContainer>
    ) : (
        <>
            <Card
                title={t(
                    'Page.LoanApplicationPage.Tabs.Documents.DealDocuments',
                )}
            >
                {dealList.map((file: any) => {
                    if (file?.files?.length > 0) {
                        return (
                            <DataView
                                value={
                                    <FilesListComponent
                                        filesList={file?.files as DocumentDTO[]}
                                        store={store}
                                    />
                                }
                                label={file?.title}
                            />
                        );
                    }

                    if (file?.defaultValue) {
                        return (
                            <DataView value={undefined} label={file?.title} />
                        );
                    }

                    return null;
                })}
            </Card>
            <Card
                title={t(
                    'Page.LoanApplicationPage.Tabs.Documents.FinancialDocuments',
                )}
            >
                {financialList.map(
                    // eslint-disable-next-line sonarjs/no-identical-functions
                    (file) =>
                        file?.files?.length > 0 && (
                            <DataView
                                value={
                                    <FilesListComponent
                                        filesList={file?.files as DocumentDTO[]}
                                        store={store}
                                    />
                                }
                                label={file?.title}
                            />
                        ),
                )}
            </Card>
        </>
    );
});

const getDealList = (store: ApplicationDocumentsStoreClass) => {
    const dealDocumentsList = {
        [LoanApplicationType.INVOICEFINANCE]: [
            {
                title: <Trans i18nKey="Deal.Documents.TaxInvoice" />,
                files: store.applicationTaxInvoice,
            },
            {
                title: <Trans i18nKey="Deal.Documents.Contract" />,
                files: store.applicationInvoiceContract,
            },
            {
                title: <Trans i18nKey="Deal.Documents.BankAccountProof" />,
                files: store.applicationProdLetter,
            },
            {
                title: <Trans i18nKey="Deal.Documents.LoanDealAgreement" />,
                files: store.applicationSignedAgreement,
            },
            {
                title: (
                    <Trans i18nKey="Deal.Documents.ApplicationDeliveryNote" />
                ),
                files: store.applicationDeliveryNote,
            },
        ],
        [LoanApplicationType.FINANCEOFDEBTOR]: [
            {
                title: <Trans i18nKey="Deal.Documents.DebtorsContract" />,
                files: store.applicationDebtorsContract,
            },
            {
                title: <Trans i18nKey="Deal.Documents.LoanDealAgreement" />,
                files: store.applicationSignedAgreement,
            },
            {
                title: <Trans i18nKey="Deal.Documents.BankAccountProof" />,
                files: store.applicationProdLetter,
            },
            {
                title: (
                    <Trans i18nKey="Deal.Documents.ApplicationDeliveryNote" />
                ),
                files: store.applicationDeliveryNote,
            },
        ],
        [LoanApplicationType.OUTANDOUTINVOICEFINANCE]: [
            {
                title: <Trans i18nKey="Deal.Documents.TaxInvoice" />,
                files: store.applicationTaxInvoice,
            },
            {
                title: <Trans i18nKey="Deal.Documents.Contract" />,
                files: store.applicationInvoiceContract,
            },
            {
                title: <Trans i18nKey="Deal.Documents.LoanDealAgreement" />,
                files: store.applicationSignedAgreement,
            },
            {
                title: <Trans i18nKey="Deal.Documents.BankAccountProof" />,
                files: store.applicationProdLetter,
            },
            {
                title: (
                    <Trans i18nKey="Deal.Documents.ApplicationDeliveryNote" />
                ),
                files: store.applicationDeliveryNote,
            },
        ],
        [LoanApplicationType.PURCHASEORDERFINANCE]: [
            {
                title: <Trans i18nKey="Deal.Documents.PurchaseOrder" />,
                files: store.applicationPurcheseOrder,
            },
            {
                title: <Trans i18nKey="Deal.Documents.Contract" />,
                files: store.applicationPurcheseOrderContract,
                defaultValue: true,
            },
            {
                title: <Trans i18nKey="Deal.Documents.LoanDealAgreement" />,
                files: store.applicationSignedAgreement,
            },
            {
                title: <Trans i18nKey="Deal.Documents.BankAccountProof" />,
                files: store.applicationProdLetter,
            },
            {
                title: <Trans i18nKey="Deal.Documents.Quotations" />,
                files: store.applicationQuotations,
            },
            {
                title: (
                    <Trans i18nKey="Deal.Documents.ApplicationDeliveryNote" />
                ),
                files: store.applicationDeliveryNote,
            },
        ],
    };

    return (
        (ApplicationStore.currentItem?.type &&
            dealDocumentsList[ApplicationStore.currentItem?.type]) ||
        []
    );
};

const getFinancialList = (store: ApplicationDocumentsStoreClass) => {
    const financialDocumentsList = {
        [LoanApplicationType.INVOICEFINANCE]: [
            {
                title: <Trans i18nKey="Deal.Documents.BankStatement" />,
                files: store.applicationBankStatement,
            },
        ],
        [LoanApplicationType.FINANCEOFDEBTOR]: [
            {
                title: <Trans i18nKey="Deal.Documents.ProfitAndLossBudget" />,
                files: store.applicationProfitAndLossBudget,
            },
            {
                title: <Trans i18nKey="Deal.Documents.FinancialStatement" />,
                files: store.applicationFinancialStatement,
            },
            {
                title: <Trans i18nKey="Deal.Documents.BankStatement" />,
                files: store.applicationBankStatement,
            },
            {
                title: <Trans i18nKey="Deal.Documents.SignedAgreement" />,
                files: store.customerSignedAgreement,
            },
        ],
        [LoanApplicationType.OUTANDOUTINVOICEFINANCE]: [
            {
                title: <Trans i18nKey="Deal.Documents.BankStatement" />,
                files: store.applicationBankStatement,
            },
        ],
        [LoanApplicationType.PURCHASEORDERFINANCE]: [
            {
                title: <Trans i18nKey="Deal.Documents.ProfitAndLossBudget" />,
                files: store.applicationProfitAndLossBudget,
            },
            {
                title: <Trans i18nKey="Deal.Documents.FinancialStatement" />,
                files: store.applicationFinancialStatement,
            },
            {
                title: <Trans i18nKey="Deal.Documents.BankStatement" />,
                files: store.applicationBankStatement,
            },
            {
                title: <Trans i18nKey="Deal.Documents.SignedAgreement" />,
                files: store.customerSignedAgreement,
            },
        ],
    };

    return (
        (ApplicationStore.currentItem?.type &&
            financialDocumentsList[ApplicationStore.currentItem?.type]) ||
        []
    );
};

const StyledSpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
